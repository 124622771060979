import React from 'react'
import Seo from '../components/SEO'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import PageHero from '../components/shared/page-hero'
import PageBody, { PageContainer } from '../components/shared/page-body'

const ReferralRewardsPage = ({ data }) => (
  <Layout>
    <Seo title="Referral Rewards" pathname="/referral-rewards/ " />
    <PageHero image={data.hero.childImageSharp} title="Referral Rewards" />
    <PageBody>
      <PageContainer maxWidth={760} padded>
        <h1 className="heading--h1">Referral Rewards</h1>
        <p>
          How often do we visit a restaurant, enjoy the food, wine and ambiance
          and then recommend it to our friends? Often? Are we rewarded from the
          restaurant&hellip; a bottle of wine or a discount? Very rarely?
        </p>
        <p>
          Human nature as it is, we all tend to criticise more than we
          compliment. We recognise that to receive a compliment is great, but
          unusual, so we would like to encourage our clients to compliment us
          and recommend us, if they think we deserve it.
        </p>
        <p>
          To reward them for this, every client who makes a recommendation and
          we receive an order for a tailored suit will receive a £50 Gift
          Voucher. This can go towards a made to measure shirt, or if several
          recommendations, accumulate the vouchers towards a new made to measure
          suit for themselves.
        </p>
        <p>
          Everyone wins. There is no better advertisement than a recommendation.
        </p>
      </PageContainer>
    </PageBody>
  </Layout>
)

export default ReferralRewardsPage

export const query = graphql`
  {
    hero: file(relativePath: { eq: "img/fontwell-group-shot.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
